<template>
  <div class="global-loading" v-if="loading === true">
    <div class="global-loading-inner">
      <div class="logo">
        <!-- <div class="loading-circle"></div> -->
        <img src="../../public/img/kampwise-colored-logo.svg" alt="logo" />
        <div class="loader">
          <svg class="circular-loader" viewBox="25 25 50 50">
            <circle
              class="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#576654"
              stroke-width="1.3"
            />
          </svg>
        </div>
      </div>
      <div class="text">
        <p class="loading-text">Chargement en cours...</p>
        <p class="loading-description">
          Nous mettons tout en œuvre pour rendre votre expérience aussi fluide
          que possible. Merci de patienter un instant
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "global-loading",

  props: {
    loading: false,
  },

  setup() {},

  data() {
    return {};
  },

  methods: {},

  watch: {},
};
</script>

<style lang="scss" scoped>
.global-loading {
  width: 100vw;
  height: 100vh;
  background-color: #f5f6ec;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  &-inner {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .logo {
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: solid 6px #e5e7eb;
      position: relative;
      /* .loading-circle {
        background-color: transparent;
        border: 6px solid #576654;
        opacity: 1;
        border-top: 6px solid rgba(0, 0, 0, 0);
        border-left: 6px solid rgba(0, 0, 0, 0);
        border-right: 6px solid rgba(0, 0, 0, 0);
        border-radius: 100%;
        box-shadow: 0 0 15px #2187e7; 
        width: 200px;
        height: 200px;
        margin: 0 auto;
        position: absolute;
        top: -6px;
        left: -6px;
        animation: spin-counterclockwise 1.6s infinite linear;
      } */
      .loader {
        position: relative;
        margin: 0px auto;
        width: 242px;
        height: 242px;
        position: absolute;
        top: -27px;
        left: -27px;
        .circular-loader {
          -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
          height: 100%;
          -webkit-transform-origin: center center;
          -ms-transform-origin: center center;
          transform-origin: center center;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          margin: auto;
        }
        .loader-path {
          stroke-dasharray: 150, 200;
          stroke-dashoffset: -10;
          -webkit-animation: dash 1.5s ease-in-out infinite,
            color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite,
            color 6s ease-in-out infinite;
          stroke-linecap: round;
        }
        @-webkit-keyframes rotate {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes rotate {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @-webkit-keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }
        @keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }
        @-webkit-keyframes color {
          0% {
            stroke: #576654;
          }
          40% {
            stroke: #576654;
          }
          66% {
            stroke: #576654;
          }
          80%,
          90% {
            stroke: #576654;
          }
        }
        @keyframes color {
          0% {
            stroke: #576654;
          }
          40% {
            stroke: #576654;
          }
          66% {
            stroke: #576654;
          }
          80%,
          90% {
            stroke: #576654;
          }
        }
      }
      img {
        width: 76px;
        height: auto;
      }
    }
    .text {
      margin: 40px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .loading-text {
        max-width: 600px;
        font-size: 24px;
        line-height: 32px;
        font-family: "Greycliff CF Bold", sans-serif;
        color: #1d2939;
      }
      .loading-description {
        max-width: 600px;
        margin: 4px 0 0 0;
        font-size: 14px;
        line-height: 21px;
        color: #667085;
        text-align: center;
        font-family: "Greycliff CF Light", sans-serif;
      }
    }
  }
  @keyframes spin-counterclockwise {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>